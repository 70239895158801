import React from'react';
import './SignInSwitcher.style.scss';

type Props ={
    active: boolean,
    setActive(active:boolean): void
}

function SignInSwicher({active, setActive}: Props){
  return (
    <div className='switcher-container'>
            <div className='switcher-buttons-container'>
                <div 
                    className={`switcher-button ${active ? 'active' : ''} sign-in`} 
                    onClick={() => setActive(active ? active : !active)}>
                    <div>Intră în cont</div>
                </div>
                <div 
                    className={`switcher-button ${active ? '' : 'active'} sign-up`} 
                    onClick={() => setActive(active ? !active : active)}>
                <div>Înregistrează-te</div>
            </div>
        </div>
        <div className='switcher-line-container'>
            <span className={`switcher-line ${active ? 'line-left' : 'line-right'}`}></span>
        </div>
    </div>
  )
};

export default SignInSwicher;