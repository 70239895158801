import React from 'react';
import { ReactComponent as DeleteSVG } from '../../../assets/svgs/delete.svg';
import './DeleteButton.style.scss'; 

type Props = {
    handleDelete: () => void
}

function DeleteButton({handleDelete}: Props){
    return(
        <div className='delete-button' onClick={handleDelete}>
            <DeleteSVG className = 'svg'/>
        </div>
    );
}

export default DeleteButton;