import React from 'react';
import Header from '../../components/Header/Header.component';
import HomeBody from '../../layouts/Home/HomeBody.layout';
import SideBar from '../../components/Sidebar/SideBar.component';
import CartBar from '../../components/Cart/CartBar.component';

function Home() {

    const [navState, setNavState] = React.useState(false);
    const [cartOpen, setCartOpen] = React.useState(false);

    const toggleCart = () => {
        setCartOpen(!cartOpen);
    }

    const toggleNav = () => {
        setNavState(prevState => !prevState);
    };

    return(
        <>
            <Header 
                toggleNav = {toggleNav}
                toggleCart = {toggleCart}
                navIsToggled = {navState}
            />
            <CartBar isOpen = {cartOpen}/>
            <SideBar 
                navIsToggled = {navState}
            />
            <HomeBody
                navIsToggled = {navState}
            />
        </>
    );
}


export default Home;