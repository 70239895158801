import React from 'react';
import './ProductCard.style.scss';
import { Link } from 'react-router-dom';
//import AddFavouriteButton from '../../buttons/AddFavouriteButton/AddFavouriteButton.component';
import AddToCart from '../../buttons/AddToCart/AddToCart.component';
import { useCart } from '../../../Contexts/Cart.context';

    //todo: conditional rendering

type Props = {
    id: string,
    title: string,
    topBanner?: string,
    bottomBanner?: string,
    image: any,
    quantity: number,
    // isFavourite: boolean,
    rating?: number,
    link: string,
    price: number,
    category: string
}

function ProductCard({ id, title, topBanner, bottomBanner, image, quantity, rating, link, price, category}: Props) {

    const {increaseCartQuantity} = useCart();

    return(
        <div className='product-card-container'>
            <div className='product-card card'>
                <div className='product-image-container'>
                    
                    <img className='product-image' src={image} alt =''/>
                    
                </div>
                {topBanner && <div className='top-banner banner'>{topBanner}</div>}
                {bottomBanner && <div className='bottom-banner banner'>{bottomBanner}</div>}
                <div className='product-card-details-container'>
                    <Link to={link}>
                        <div className='product-card-title-container'>
                            <h3 className='product-card-title'>{title}</h3>
                        </div>
                    </Link>  
                    <div className='price-container'>{price}Lei{category === 'arome' ? '/100g' : ''}</div>
                    <div className='product-card-buttons-container'>
                        <AddToCart handleClick={() => {increaseCartQuantity(id, image, title, price)}} disabled={quantity > 0 ? false : true}/>
                        {/* <AddFavouriteButton 
                            isFavourite = {false} 
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;