import React from 'react';
import { useCart } from '../../Contexts/Cart.context';
import CartCard from '../../components/crads/CartCard/CartCard.component';
import './Order.style.scss';
import { ReactComponent as ArrowSVG } from '../../assets/svgs/arrow.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    navIsToggled: boolean
}

type Order = {
    userID: string
    product_ids: string[]
    quantities: number[]
    address:{
        name: string
        country: string
        county: string
        city: string
        address: string
        phone: string
    }
    payment: string
    value: number
    shipping: string
    status: string
}

type Address = {
    name: string
    country: string
    county: string
    city: string
    address: string
    phone: string
}

function OrderLayout({navIsToggled}: Props) {

    const defaultState: Order = {
        userID: '',
        product_ids: [],
        quantities: [],
        address:{
            name: '',
            country: '',
            county: '',
            city: '',
            address: '',
            phone: '',
        },
        payment: 'Cash',
        value: 0,
        shipping: '',
        status: 'Pending',
    }

    const defaultAddress: Address = {
        name: '',
        country: 'ro',
        county: '',
        city: '',
        address: '',
        phone: ''
    }
    //user
    const [validUser, setVaildUser] = React.useState(false);
    //order and address data
    const [order, setOrder] = React.useState<Order>(defaultState);
    const [address, setAddress] = React.useState<Address>(defaultAddress);
    //pricing
    const [shippingCost, setShippingCost] = React.useState(0);
    const [finalPrice, setFinalPrice] = React.useState(0);
    //button status
    const [disabled, setDisabled] = React.useState(true);
    //cart context
    const { getItemQuantity, clearCart, cartItems, cartQuantity, totalValue , productIDs } = useCart();
    //open inputs
    const [productsOpen, setProductsOpen] = React.useState(false);
    const [addressOpen, setAddressOpen] = React.useState(false);
    const [shippingOpen, setShippingOpen] = React.useState(false);
    const [paymentOpen, setPaymentOpen] = React.useState(false);
    //order succesful
    const [succesful, setSuccesful] = React.useState(true);
    const [error, setError] = React.useState(true);

    //watch for cart changes
    React.useEffect(() => {

        const quantityArray = productIDs.map(id => getItemQuantity(id));

        setOrder((prevData) => {
            return{
                ...prevData,
                value: finalPrice,
                product_ids: [...productIDs],
                quantities: [...quantityArray],
            }
        })
    }, [productIDs]);

    React.useEffect(() => {
        if(window.localStorage.getItem('Authorization') !== null){
            axios.get(`${REACT_APP_BACKEND_URL}/api/users/validate`, {
                params: {
                    Authorization: window.localStorage.getItem('Authorization')!
                }
            }).then((response) => {
                setVaildUser(response.data.user);
            })
        }
    },[])

    React.useEffect(()=>{
        setOrder((prevData) => {
            return{
                ...prevData,
                value: finalPrice
            }
        })
    },[finalPrice])

    //watch for address changes
    React.useEffect(() => {
        setOrder((prevData) => {
            return{
                ...prevData,
                address: {...address}
            }
        })
    },[address])

    //handle address change
    const handleAddressChange = (event: React.FormEvent) => {
        setAddress((prevData) => {
            return{
                ...prevData,
                //@ts-ignore
                [event.target.name]: event.target.value
            }
        });
    }

    //handle order change
    const handleOrderChange = (event: React.FormEvent) => {
        setOrder((prevData) => {
            return{
                ...prevData,
                //@ts-ignore
                [event.target.name]: event.target.value
            }
        })
    }

    //handle order submiting
    const handleSubmit = (event: React.MouseEvent) => {
        event.preventDefault();

        axios.post(`${REACT_APP_BACKEND_URL}/api/orders/add`, order, {
            params :{
                Authorization: window.localStorage.getItem('Authorization')!
            }
        }).then((response) => {
            setSuccesful(true);
            setDisabled(true);
            setError(false);
            clearCart();
        }).catch((response) => {
            console.log()
            setSuccesful(false);
            setError(true);
        })
    }

    //set shipping cost
    React.useEffect(()=>{
        if(order?.shipping === 'Courier' && totalValue < 2500) setShippingCost(25);
        else setShippingCost(0);
        setFinalPrice(shippingCost + totalValue);
    }, [totalValue, order?.shipping, shippingCost]);

    //check inputs
    React.useEffect(() => {
        //has auth
        if(!window.localStorage.getItem('Authorization')) return;
        //has products
        if(cartItems.length <= 0) return;
        //has address
        if(!address.name || !address.county || !address.city || !address.address || !address.phone)  return;
        //has shipping & payment
        if(!order.payment || !order.shipping) return;
        setDisabled(false);

    }, [cartItems, order, address, succesful])

    //render card if cart !empty
    const renderCards = cartQuantity === 0 
        ? <div className='empty'>Cosul este gol!</div> 
        : cartItems.map((item) => {
            return(<CartCard 
                key={item.id}
                id = {item.id}
                imageUrl = {item.imageUrl}
                title = {item.title}
                price = {item.price}
                wide = {true}
            />)
    }); 

    return(
        <main className={`main-body-container${navIsToggled ? '-to-right' : ''}`}>
            <div className='main-body'>
                <h1 className='order-title'>Finalizează comanda</h1>
                {
                    succesful && !error && <div className='success'>Comanda ta a fost finalizata cu succes!</div>
                }
                {
                    !succesful && error && <div className='error'>A aparut o problema! Te rugam sa incerci din nou mai tarziu!</div>
                }
                {   
                    !window.localStorage.getItem('Authorization') &&
                    <div className='no-auth'>
                        Pentru a finaliza comanda vă rugam să intrați în cont sau să vă înregistrați! <Link to='/profile'>aici!</Link>
                    </div>
                }
                { window.localStorage.getItem('Authorization') && validUser && <>
                {/* products */}
                <div className={`order-section border-top ${productsOpen? '' : 'open' }`}>
                    <div className='title-container'>
                        <h3 className='section-title '>Produse</h3>
                        <div className={`arrow-container ${productsOpen? 'down' : '' }`} 
                            onClick={() => {setProductsOpen(!productsOpen)}}
                        >
                            {<ArrowSVG/>}
                        </div>    
                    </div>
                    <div className='contents'>
                        {renderCards}
                    </div>
                </div>

                {/* address */}
                <div className={`order-section border-top ${addressOpen? '' : 'open' }`}>
                    <div className='title-container'>
                        <h3 className='section-title '>Adresa de livrare</h3>
                        <div className={`arrow-container ${addressOpen? 'down' : '' }`} 
                            onClick={() => {setAddressOpen(!addressOpen)}}
                        >
                            {<ArrowSVG/>}
                        </div>    
                    </div>
                    <div className='contents'>
                        <form className='address-form'>
                            <label htmlFor='Name' className='address-label'>Nume și prenume</label>
                            <input type='text' className='order-input' name='name' id='Name' placeholder='Nume și prenume' onChange={handleAddressChange}/>
                            <span className='bottom-border'></span>

                            <label htmlFor='County' className='address-label'>Județ</label>
                            <select name="county" id="County" className='order-input' defaultValue='' onChange={handleAddressChange}>
                                <option value="">Alege județul</option>
                                <option value="Alba">Alba</option>
                                <option value="Arges">Argeș</option>
                                <option value="Arad">Arad</option>
                                <option value="Bucuresti" >București</option>
                                <option value="Bacau">Bacău</option>
                                <option value="Bihor">Bihor</option>
                                <option value="Bistrita">Bistrița</option>
                                <option value="Braila">Brăila</option>
                                <option value="Botosani">Botoșani</option>
                                <option value="Brasov">Brașov</option>
                                <option value="Busau">Buzău</option>
                                <option value="Cluj">Cluj</option>
                                <option value="Calaeasi">Călărași</option>
                                <option value="Caras_severin">Caraș-Severin</option>
                                <option value="Constanta">Constanța</option>
                                <option value="Covasna">Covasna</option>
                                <option value="Dambovita">Dâmbovița</option>
                                <option value="Dolj">Dolj</option>
                                <option value="Gorj">Gorj</option>
                                <option value="Galati">Galați</option>
                                <option value="Giurgiu">Giurgiu</option>
                                <option value="Hunedoara">Hunedoara</option>
                                <option value="Hargita">Harghita</option>
                                <option value="Ilfov">Ilfov</option>
                                <option value="Ialomita">Ialomița</option>
                                <option value="Iasi">Iași</option>
                                <option value="Mehedinti">Mehedinți</option>
                                <option value="Maramure">Maramureș</option>
                                <option value="Mures">Mureș</option>
                                <option value="Neamt">Neamț</option>
                                <option value="Olt">Olt</option>
                                <option value="Prahova">Prahova</option>
                                <option value="Sibiu">Sibiu</option>
                                <option value="Salaj">Sălaj</option>
                                <option value="Satu-Mare">Satu-Mare</option>
                                <option value="Suceava">Suceava</option>
                                <option value="Tulcea">Tulcea</option>
                                <option value="Timis">Timiș</option>
                                <option value="Teleorman">Teleorman</option>
                                <option value="Valcea">Vâlcea</option>
                                <option value="Vrancea">Vrancea</option>
                                <option value="Vaslui">Vaslui</option>
                            </select>
                            <span className='bottom-border'></span>

                            <label htmlFor='City' className='address-label'>Localitatea</label>
                            <input type='text' className='order-input' name='city' id='City' placeholder='Localitatea' onChange={handleAddressChange}/>
                            <span className='bottom-border'></span>

                            <label htmlFor='Address' className='address-label'>Adresa</label>
                            <input type="text" className='order-input' name='address' id='Address' placeholder='Strada, numărul, bloc, apartament' onChange={handleAddressChange}/>
                            <span className='bottom-border'></span>

                            <label htmlFor='Phone' className='address-label'>Numar telefon</label>
                            <input type="text" className='order-input' name='phone' id='Phone' placeholder='Numar telefon' onChange={handleAddressChange}/>
                            <span className='bottom-border'></span>
                        </form>
                    </div>
                </div>

                {/* shipping */}
                <div className={`order-section border-top ${shippingOpen? '' : 'open' }`}>
                    <div className='title-container'>
                        <h3 className='section-title '>Metoda de livrare</h3>
                        <div className={`arrow-container ${shippingOpen? 'down' : '' }`} 
                            onClick={() => {setShippingOpen(!shippingOpen)}}
                        >
                            {<ArrowSVG/>}
                        </div>    
                    </div>
                    <div className='contents radio-buttons'>
                        <label className='radio-label' htmlFor='PickUp'>
                            <input type="radio" value="PickUp" id="PickUp" name="shipping" className='radio-button' onChange={handleOrderChange}/>Ridicare personală (Oradea)
                        </label>
                        <label className='radio-label' htmlFor='Courier'>
                            <input type="radio" value="Courier" id="Courier" name="shipping" className='radio-button' onChange={handleOrderChange}/>Livrare prin curier
                        </label>
                    </div>
                </div>

                {/* payment */}
                <div className={`order-section border-top ${paymentOpen? '' : 'open' }`}>
                    <div className='title-container'>
                        <h3 className='section-title '>Metoda de plată</h3>
                        <div className={`arrow-container ${paymentOpen? 'down' : '' }`} 
                            onClick={() => {setPaymentOpen(!paymentOpen)}}
                        >
                            {<ArrowSVG/>}
                        </div>    
                    </div>
                    <div className='contents radio-buttons'>
                        <label className='radio-label' htmlFor='cash'>
                            <input type="radio" value="Cash" id="Cash" name="payment" className='radio-button' onChange={handleOrderChange} checked/>Ramburs
                        </label>
                    </div>
                </div>

                {/* empty div for spacing */}
                <div className='empty'></div>

                {/* fixed position submit and price */}
                <div className='order-price-submit'>
                    <div className='final-value'>Pret final: {finalPrice}Lei</div>
                    <button className={`submit-order ${disabled? 'disabled':''}`} onClick={handleSubmit} disabled={disabled}>Confirmă comanda!</button>
                </div></>}
            </div>
        </main>
    );
}

export default OrderLayout;