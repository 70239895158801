import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/css/navigation';
import HomeSwiperCard from '../../components/crads/HomeSwiperCard/HomeSwiperCard.component';

function SwiperLib(props: any) {

    SwiperCore.use([Autoplay]);
    const SwiperCards = [...props.cards];
    const renderSwiperCards = SwiperCards.map((cardData) => {
        return(
            <SwiperSlide className='swiper-slide' key = {cardData.key}>
                <HomeSwiperCard 
                    title = {cardData.title}
                    description = {cardData.description}
                    newPrice = {cardData.newPrice}
                    oldPrice ={cardData.oldPrice}
                    discount = {cardData.discount}
                    image = {cardData.image}
                    link = {cardData.link}
                />
            </SwiperSlide>
        )
    });

    return(
        <Swiper 
            modules={[Navigation]}
            navigation
            speed={400}
            slidesPerView={1}
            loop
            className='swiper'
            autoplay={{
                delay: 2000
            }}
        >
            {renderSwiperCards}
        </Swiper>
    );
}


export default SwiperLib;