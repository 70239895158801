import React from 'react';
import './Product.style.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import AddToCart from '../../components/buttons/AddToCart/AddToCart.component';
//@ts-ignore
import { ReactComponent as ArrowSVG } from '../../assets/svgs/arrow.svg';
import { useCart } from '../../Contexts/Cart.context';
import RecomendedProducts from '../../components/products/Recomended/RecomendedProducts.component';
import SimilarProducts from '../../components/products/Similar/SimilarProducts.component';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    navIsToggled: boolean,
    product: {
        _id: string,
        images: string[],
        path: string,
        title: string,
        category: string,
        description: string,
        price: any,
        quantity: any,
        oldPrice?: any,
        discount?: any 
    },
    error: boolean,
    loading: boolean
}

function ProductLayout({ navIsToggled, product, error, loading } : Props) {


    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [recomendedOpen, setRecomendedOpen] = React.useState(true);
    const [similarOpen, setSimilarOpen] = React.useState(true);
    const { increaseCartQuantity } = useCart();

    const renderSwiperCards = loading ? <></> : product.images.map((image:any) => {
        return(
            <SwiperSlide key={image}>
                <img src={`${REACT_APP_BACKEND_URL}/public/images/${product.path}/${image}`} alt='product' className='product-image' />
            </SwiperSlide>
        )
    })

    return(
        <main className={`main-body-container${navIsToggled ? '-to-right' : ''}`}>
            <div className='main-body'>
                {error && <div className='error'>Acest produs nu exista sau nu mai face parte din oferta noastra!</div>}
                    <>
                        <h2 className='product-title'>{product.title}</h2>
                        <div className='link-container'>
                            <Link to='../shop' className='link'>magazin</Link>
                            <span className='link'>/</span>
                            <Link to={`../shop/${product.category}`} className='link'>{product.category}</Link>
                        </div>
                        <div className='swiper-price-container'>
                            <Swiper
                                className='product-swiper' 
                                modules={[Navigation]}
                                navigation
                                speed={400}
                                slidesPerView={1}
                                loop
                            >
                                {renderSwiperCards}
                            </Swiper>
                            <div className='stoc-and-price-container'>
                                {product.quantity >= 10 && <div className='quantity'>In stoc</div>}
                                {product.quantity > 0 && product.quantity < 10 && <div className='quantity yellow'>Stoc limitat</div>}
                                {product.quantity === 0 && <div className='quantity red'>Stoc epuizat</div>}
                                <div className='price-add-to-cart'>
                                    <div className='price-container'>
                                        <div className='price'>{product.price}Lei{product.category === 'arome' ? '/100g' : ''}</div>
                                        {
                                            (product.oldPrice || product.discount) && 
                                            <div className='discount-container'>
                                                <div className='old-price'>{product.oldPrice}Lei</div>
                                                {product.discount && <div className='discount'>{product.discount*-100}%</div>}
                                            </div>
                                        }
                                    </div>
                                    <AddToCart text = 'Adaugă în coș' background={true} disabled={product.quantity > 0 ? false : true} handleClick={() => {increaseCartQuantity(product._id, `${REACT_APP_BACKEND_URL}/public/images/${product.path}/${product.images[0]}`, product.title, product.price)}}/>
                                </div>
                            </div>
                        </div>
                        <div className={`details border-top ${detailsOpen? 'open' : '' }`}>
                            <div className='title-container'>
                                <h3 className = 'description-title '>Descriere</h3>
                                <div className={`arrow-container ${detailsOpen? '' : 'down' }`} onClick={() => {setDetailsOpen(!detailsOpen)}}>
                                    {<ArrowSVG/>}
                                </div>
                            </div>
                            <p className='description'>{product.description}</p>
                        </div>
                        <div className={`recomended border-top ${recomendedOpen? 'open' : '' }`}>
                            <div className='title-container'>
                                <h3 className = 'recomended-title'>Produse recomandate</h3>
                                <div className={`arrow-container ${recomendedOpen? '' : 'down' }`} onClick={() => {setRecomendedOpen(!recomendedOpen)}}>
                                    {<ArrowSVG/>}
                                </div>
                            </div>
                            <RecomendedProducts category = {product.category}/>
                        </div>
                        <div className={`recomended border-top ${similarOpen? 'open' : '' }`}>
                            <div className='title-container'>
                                <h3 className = 'recomended-title'>Produse similare</h3>
                                <div className={`arrow-container ${similarOpen? '' : 'down' }`} onClick={() => {setSimilarOpen(!similarOpen)}}>
                                    {<ArrowSVG/>}
                                </div>
                            </div>
                            <SimilarProducts category = {product.category}/>
                        </div>
                    </>
            </div>
        </main>
    );
}

export default ProductLayout;