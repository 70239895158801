import React from 'react';
import { Link } from 'react-router-dom';
import './HomeSwiperCard.style.scss';

type HomeSwiperCardProps = {
  link: string;
  image: string;
  title?: string;
  description?: string;
  newPrice?: number;
  oldPrice?: number;
  discount?: number;
};

function HomeSwiperCard({ link, image, title, description, newPrice, oldPrice, discount }: HomeSwiperCardProps) {
  return (
    <div className='swiper-card-container'>
      <Link to={link}>
        <div className="swiper-card card" style={{ backgroundImage: `url(${image})`}}>
          <div className='details-container'>
            <div className="title-container">
              <h2 className='title'>{title}</h2>
            </div>
            <div className="description-container">
              <p className='description'>{description}</p>
            </div>
            <div className="price-container">
              <div className="new-price-container">
                <p className='new-price'>{newPrice ? `${newPrice}Lei` : '' }</p>
              </div>
              <div className="old-price-container">
                <p className='price-cut'>{oldPrice ? `${oldPrice}Lei` : '' }</p>
              </div>
              <div className="discount-container">
                <p className='discount'>{discount ? `-${discount}%` : '' }</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HomeSwiperCard;
