import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Shop from "./pages/shop/Shop.page";
import Home from "./pages/home/Home.page";
import Profile from "./pages/profile/Profile.page";
import GenericNotFound from "./pages/genericNotFound/GenericNotFound.page";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.component";
import ManageStore from "./pages/manage/ManageStore.page";
import ProductPage from "./pages/ProductPage/Product.page";
import Testpage from "./pages/test.page";
import OrderPage from "./pages/order/Order.page";
import FirstVisit from "./components/FirstVisit/FirstVisit.component";


const Router = () => {

    const [firstTime, setFirstTime] = React.useState(true);

    React.useEffect(() => {
        const isFirstTime = localStorage.getItem('isFirstTime');
        if(isFirstTime){
            setFirstTime(false);
        }
    }, [])

    const handleClick = () => {
        setFirstTime(false); 
        window.localStorage.setItem('isFirstTime', 'false');
    }

    return(
        <>
            {firstTime && <FirstVisit handleClick={handleClick}/>}
            {!firstTime && 
            <BrowserRouter>
                <Routes>
                    <Route path = '/'  >
                        <Route index element = { <Home/> } />
                        <Route path='profile' element = { <Profile isSignedIn = {false} /> } />
                        {/*//@ts-ignore*/}
                        <Route path='manage' element = { <ProtectedRoute protectedElement = {<ManageStore/>} /> } />
                        <Route path='shop'>
                            <Route path="" element={<Shop />} />
                            <Route path=":category" element={<Shop />} />
                        </Route>
                        <Route path="product/:id" element={<ProductPage />} />
                        <Route path="order" element ={<OrderPage/>} />
                        <Route path="test/:asd" element={<Testpage />} />
                        <Route path="*" element={<GenericNotFound/>} />
                    </Route>
                </Routes>
            </BrowserRouter>}
        </>
    );
}

export default Router;