import React from 'react';
import './SideBarCard.style.scss';
import { Link } from 'react-router-dom';

type Props = {
  link: string,
  title: string,
  image: string
};

function SideBarCard({ link, title, image }: Props) {
  return (
    <div className='card-container'>
      <Link to={link}>
        <div className='card'>
          <div className='card-image-container'>
            <img className='card-image' src={image} alt='' />
          </div>
          <div className='card-title-container'>
            <h2 className='card-title'>{title}</h2>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default SideBarCard;
