import React from'react';
import { ReactComponent as CartSVG } from '../../../assets/svgs/shopping-cart.svg';
import './AddToCart.style.scss';

type Props = {
  text?: String,
  background?: boolean,
  handleClick?: () => void,
  disabled: boolean  
}

function AddToCart({text, background = false, handleClick, disabled}: Props){
  return (
      <button className={`add-to-cart-button ${background? 'has-background':''}`} onClick={handleClick} disabled = {disabled}>
        <CartSVG />
        {text}
      </button>
  )
};

export default AddToCart;