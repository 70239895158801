import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

type Props = {
    children: React.ReactNode
}

type CartItem = {
    id: string
    quantity: number
    imageUrl: string
    title: string
    price: number
}
  
type CartContextType = {
    getItemQuantity: (id: string) => number
    increaseCartQuantity: (id: string, imageUrl?: string, title?: string, price?: number) => void
    decreaseCartQuantity: (id: string) => void
    removeFromCart: (id: string) => void
    clearCart: () => void
    cartQuantity: number
    totalValue: number
    cartItems: CartItem[]
    productIDs: string[]
}

const CartContext = createContext({} as CartContextType);

export function useCart(){
    return useContext(CartContext);
}

export function CartProvider({children}: Props){
    
    const [cartItems, setCartItems] = useLocalStorage<CartItem[]>('cart', []);

    const cartQuantity = cartItems.reduce((quantity, item) =>
        item.quantity + quantity, 0
    )

    const totalValue = cartItems.reduce((totalValue, item) => 
        (item.quantity * item.price + totalValue), 0
    )

    const productIDs = cartItems.map((item) => {
        return item.id;
    })

    function getItemQuantity(id: string){
        return cartItems.find(items => items.id === id)?.quantity || 0;
    }

    function increaseCartQuantity(id: string, imageUrl: string, title: string, price: number){
        setCartItems( currentItems => {
            if(currentItems.find(item => item.id === id) == null){
                return [...currentItems, {id, imageUrl, title, price, quantity: 1}];
            } else {
                return currentItems.map( item => {
                    if(item.id === id){
                        return{ ...item, quantity: item.quantity + 1};
                    } else {
                        return item;
                    }
                })
            }
        })
    }

    function decreaseCartQuantity(id: string){
        setCartItems( currentItems => {
            if(currentItems.find(item => item.id === id)?.quantity === 1){
                return currentItems.filter(item => item.id !== id);
            } else {
                return currentItems.map( item => {
                    if(item.id === id){
                        return{ ...item, quantity: item.quantity - 1};
                    } else {
                        return item;
                    }
                })
            }
        })
    }

    function removeFromCart(id: string){
        setCartItems(currentItems => {
            return currentItems.filter(item => item.id !== id);
        })
    }

    function clearCart(){
        cartItems.forEach(item => {
            removeFromCart(item.id);
        })
    }
    
    return <CartContext.Provider value = {{ getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart, clearCart, cartQuantity, cartItems, totalValue, productIDs } as CartContextType} >
        {children}
    </CartContext.Provider>
}