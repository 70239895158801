import React from 'react';
import './CartCard.style.scss';
import { useCart } from '../../../Contexts/Cart.context';

type Props ={
    id: string,
    imageUrl: string
    title: string,
    price: number,
    wide?: boolean
}

function CartCard({id, imageUrl, title, price, wide = false}: Props) {

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart} = useCart();

    const quantity = getItemQuantity(id);

    return(
        <div className = {`cart-card ${wide? 'wide':''}`}>
            <img alt='cart item' className='cart-image' src={imageUrl}/>
            <div className='card-title-and-buttons'>
                <div className='cart-card-title'>
                    {title}
                </div>
                <div className='card-buttons-container'>
                    <div className='change-value'>
                        <button className='cart-button value' onClick={() => decreaseCartQuantity(id)}>-</button>
                        <span className='number'>{quantity}</span>buc.
                        <button className='cart-button value' onClick={() => increaseCartQuantity(id)}>+</button>
                    </div>
                    <button className='cart-button delete' onClick={() => removeFromCart(id)}>Sterge</button>
                    <p className='price'>Pret: {price * quantity}Lei</p>
                </div>
            </div>
        </div>
    );
}

export default CartCard;