import React from 'react';
import './NavButton.style.scss';

type NavButtonProps = {
  navIsToggled: boolean;
  toggleNav: () => void;
};

function NavButton({navIsToggled, toggleNav}: NavButtonProps) {
  return (
    <div className={`nav-button${navIsToggled ? '-toggled' : ''}`}>
      <button className='lines-container' type='button' onClick={toggleNav}>
        <span className='nav-line line-top'></span>
        <span className='nav-line line-middle'></span>
        <span className='nav-line line-bottom'></span>
      </button>
    </div>
  );
}

export default NavButton;
