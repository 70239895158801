import React from 'react';
import './maintenance.style.scss';
import logo from '../../assets/images/logos/kalian-logo-round.png';

function MaintenancePage() {
    return(
        <div className='page'>
            <div className='image-container'>
                <img src={logo} alt='logo' />
            </div>
            <div className='text-container'>
                Pagina se va deschide în data de 28 august, la ora 08:08!
            </div>
        </div>
    );
}

export default MaintenancePage;