import React from 'react';
import Header from '../../components/Header/Header.component';
import SideBar from '../../components/Sidebar/SideBar.component';
import CartBar from '../../components/Cart/CartBar.component';
import OrderLayout from '../../layouts/Order/Order.layout';

function OrderPage() {
    const [navState, setNavState] = React.useState(false);
    const [cartOpen, setCartOpen] = React.useState(false);

    const toggleCart = () => {
        setCartOpen(!cartOpen);
    }

    const toggleNav = () => {
        setNavState(prevState => !prevState);
    };

    return(
        <>
            <Header 
                toggleNav = {toggleNav}
                toggleCart = {toggleCart}
                navIsToggled = {navState}
            />
            <CartBar isOpen = {cartOpen}/>
            <SideBar 
                navIsToggled = {navState}
            />
            <OrderLayout navIsToggled = {navState}/>
        </>
    );
}

export default OrderPage;