import React from 'react';
import '../Profile.style.scss';
import './ProfileSignIn.style.scss';
import SignInSwitcher from '../../../components/profile/SignInSwitcher/SignInSwitcher.component';
import SignInForm from '../../../components/profile/SignInForm/SignInForm.component';
import SignUpForm from '../../../components/profile/SignUpForm/SignUpForm.component';

type Props = {
    navIsToggled: boolean
}

function ProfileSignIn({navIsToggled}: Props) {
    const [active, setActive] = React.useState(true);
    

    return(
        <main className={`signin-signup-body-container${navIsToggled ? '-to-right' : ''}`}>
            <SignInSwitcher 
                active = {active}
                setActive = {setActive}
            />
            <SignInForm 
                active = {active}
            />
            <SignUpForm
                active = {active}
            />
        </main>
    );
}

export default ProfileSignIn;