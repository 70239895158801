import React from 'react';
import './HomeCategories.style.scss';
import CategoriesCard from '../../crads/CaregoriesCard/CategoriesCard.component';
import hookahs from '../../../assets/images/categories/hookahs.jpg';
import flavors from '../../../assets/images/categories/flavors.jpg';
import coals from '../../../assets/images/categories/coals.webp';
import accessories from '../../../assets/images/categories/hookah-accessories.webp';

type Category = {
  title: string;
  image: string;
  link: string;
  key: number;
}

function HomeCategories() {
    const categories: Category[] = [
        {
          key: 0,
          title: 'Arome',
          image: flavors,
          link: '/shop/arome'
        },
        {
          key: 1,
          title: 'Narghilea',
          image: hookahs,
          link: '/shop/narghilea'
        },
        {
          key: 2,
          title: 'Cărbuni',
          image: coals,
          link: '/shop/carbuni'
        },
        {
          key: 3,
          title: 'Accesorii',
          image: accessories,
          link: '/shop/accesorii'
        }
    ]
    const renderCategories = categories.map((cardData) => {
        return(
            <CategoriesCard
                key={cardData.key} 
                title={cardData.title}
                image={cardData.image}
                link={cardData.link}
            />
        )
    }); 

    return(
        <div className='categories-cards-container'>
            {renderCategories}
        </div>
    );
}

export default HomeCategories;
