import React from 'react';
import { ReactComponent as ArrowSVG} from '../../../assets/svgs/arrow.svg';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CancelConfirmationModal from '../../../components/CancelConfirmation/CancelConfirmation.component';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    navIsToggled: boolean
}

function RenderProducts({ order }: any) {

    const [ordersProducts, setOrdersProducts] = React.useState<any>([]);

    React.useEffect(() => {
        order.product_ids.forEach((id: string) => {
            axios.get(`${REACT_APP_BACKEND_URL}/api/products/` + id)
            .then((response)=>{
                setOrdersProducts((prevData: any) => {
                    return [ ...prevData, response.data];
                })
            })
        });
    }, [order])

    let uniqueProducts = ordersProducts.filter(
        (product: any, index: number, self: any[]) =>
          index === self.findIndex((p) => p._id === product._id)
    );

    let index = 0;
    const render = uniqueProducts.map((product: any) => {
        
        return(
            <div className='order-product-container' key={product._id}>
                <Link to = {`/product/${product._id}`}>
                    <div className='product'>
                        <img src={`${REACT_APP_BACKEND_URL}/public/images/${product.path}/${product.images[0]}` } className='order-image' alt='order product'/>
                        <div className='order-product-title'>{product.title}</div>
                    </div>
                </Link>
                <div>{order.quantities[index++]}buc.</div>
            </div>
        )
    })

    return(
        <>{render}</>
    );
}

function ManageAccount({navIsToggled}: Props) {

    const [ordersOpen, setOrdersOpen] = React.useState(false);
    const [orders, setOrders] = React.useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [orderIdToCancel, setOrderIdToCancel] = React.useState('');

    React.useEffect(() => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/orders/myOrders`, 
        { 
            params: { 
                Authorization: window.localStorage.getItem('Authorization')!
            }
        })
        .then((response) => {
            setOrders(response.data);
        });
    },[]);

    React.useEffect(()=> {
        setOrders((prevData: any) => {
            const sorted = prevData.sort((a: any, b: any) => b.date.localeCompare(a.date));
            return sorted;
        });
    }, [orders]);

    function handleCancel(id: any){
        //@ts-ignore
        const order: Object = orders.find(order => order._id === id)

        axios.post(`${REACT_APP_BACKEND_URL}/api/orders/update`,
        {
            ...order
        },
        {
            params: {
                Authorization: window.localStorage.getItem('Authorization')!
            }    
        });
    } 

    function onUpdate(id: string){
        const data = orders.find((order: any) => order._id === id);
        data.status = 'Cancelled';
        setOrders((prevData: any) => {
            return [...prevData.filter((data: any) => data._id !== id ), data];
        })
    }

    function handleOpenModal(orderId: any) {
        setOrderIdToCancel(orderId);
        setModalIsOpen(true);
      }
    
      // Function to close the modal
      function handleCloseModal() {
        setModalIsOpen(false);
      }
    
      // Function to handle order cancellation
      function handleCancelOrder(orderIdToCancel: any) {
        // Perform the cancellation logic here, e.g., make API request to update order status
        onUpdate(orderIdToCancel); 
        handleCancel(orderIdToCancel);
        
        // After cancelation is confirmed, close the modal
        handleCloseModal();
      }

    let sortedOrders = [...orders];
    sortedOrders = sortedOrders.sort((a, b)=> b.date.localeCompare(a.date));
    

    const renderOrders = sortedOrders.map((order: any) => {

        return(<div key = {order._id} className='account-order-card'>
            <div className='profile-order-title'>
                <strong>Comanda cu seria:</strong> {order._id}
            </div>
            <div className='profile-order-title'>
                <small>In data de: {order.date}</small>
            </div>
            <hr></hr>
            <div className='order-products-container'>
                <div className='order-products-title'><strong>Produse:</strong></div> 
                <RenderProducts order={order}/>
            </div>
            <hr></hr>
            <div className='order-value'>
                <strong>Pret final:</strong> {order.value}Lei
            </div>
            <hr></hr>
            <div className='order-status'>
                <strong>Starea comenzii:</strong> {order.status}
            </div>
            <hr></hr>
            <div>
                <button disabled = {order.status === 'Pending' || order.status === 'Recieved' || order.status === 'Processing'
                                    ? false : true}
                        onClick={(event: React.MouseEvent) => { event.preventDefault(); handleOpenModal(order._id);}}
                        className='button'
                >
                    Anuleaza comanda
                </button>
            </div>
            <CancelConfirmationModal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                onCancelConfirm={() => handleCancelOrder(orderIdToCancel)}
            />
        </div>)
    })

    return(
        <main className={`main-body-container${navIsToggled ? '-to-right' : ''}`}>
            <div className='main-body'>
                <div className="title-log-out">
                    <h1>Contul meu</h1>
                    <div className='log-out-container'>
                        <button className="log-out" onClick={(event) => {
                            event.preventDefault();
                            window.localStorage.removeItem('Authorization'); 
                            window.location.href = '/'}
                        }>
                            Log out
                        </button>
                    </div>
                </div>
                <div className={`order-section border-top ${ordersOpen? '' : 'open' }`}>
                    <div className='title-container'>
                        <h3 className='section-title '>Comenzile mele</h3>
                        <div className={`arrow-container ${ordersOpen? 'down' : '' }`} 
                            onClick={() => {setOrdersOpen(!ordersOpen)}}
                        >
                            {<ArrowSVG/>}
                        </div>    
                    </div>
                    <div className='contents'>
                        {renderOrders}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ManageAccount;