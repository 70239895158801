import React from 'react';
import './SimilarProducts.style.scss';
import ProductCard from '../../crads/ProductCard/ProductCard.component';
import LeftRightButton from '../../buttons/LeftRightButton/LeftRightButton.component';
import axios from 'axios';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    category: string
}

function SimilarProducts({category}: Props) {

    const [productArray, setProductArray] = React.useState<any>([]);

    React.useEffect( () => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/products/similar/` + category)
            .then(res => {
                //@ts-ignore
                setProductArray(productArray => productArray=res.data);
            })
    }, [category])

    //@ts-ignore
    const renderproductCards = productArray.map((cardData) => {
        return(
            <ProductCard 
                key = {cardData._id}
                id = {cardData._id}
                title = {cardData.title}
                topBanner = {cardData.topBanner}
                bottomBanner = {cardData.bottomBanner}
                image = {`${REACT_APP_BACKEND_URL}/public/images/${cardData.path}/${cardData.images[0]}`}
                quantity = {cardData.quantity}
                rating = {cardData.rating}
                link = {`/product/${cardData._id}`}
                price = {cardData.price}
                category = {cardData.category}
            />
        )
    });

    const ref = React.useRef<HTMLDivElement>(null);
    const scroll = (scrollOffset: number) => {
        if (ref.current != null)
            ref.current.scrollLeft += scrollOffset;
    };

    return(
        <div className='top-products-container'>
            <div className='products-card-container' ref={ref}>
                {renderproductCards}
            </div>
            <div className='left-button-container'>
                <LeftRightButton direction='left' onClick={() => scroll(-160)}/>
            </div>
            <div className='right-button-container'>
                <LeftRightButton direction='right' onClick={() => scroll(160)}/>
            </div>
        </div>
        
    );
}

export default SimilarProducts;