import React from 'react';
import './CartBar.style.scss';
import CartCard from '../crads/CartCard/CartCard.component';
import { useCart } from '../../Contexts/Cart.context';
import { Link } from 'react-router-dom';

type Props ={
    isOpen: boolean
}

function CartBar({isOpen}: Props) {

    const {cartItems, cartQuantity, totalValue} = useCart();

    const renderCards = cartQuantity === 0 
        ? <div className='empty'>Coșul este gol!</div> 
        : cartItems.map((item) => {
            return(<CartCard 
                key={item.id}
                id = {item.id}
                imageUrl = {item.imageUrl}
                title = {item.title}
                price = {item.price}
            />)
    }) 

    return(
        <div className={`cart-bar ${isOpen? 'cart-down' : ''}`}>
            <div className='cart-title-container'>
                <h1 className='cart-title'>Cosul meu</h1>
            </div>
            <div className='products-container'>
                {renderCards}
            </div>
            {totalValue !== 0 && <>
                <div className='total-price'>
                    Pret total: {totalValue}Lei
                </div>
                <div className='cartbar-button-container'>
                    <Link to ='/order'><button className='cart-button'>Finalizează comanda!</button></Link>
                </div>
            </>}    
        </div>
    );
}


export default CartBar;