import React from 'react';
import { useParams } from 'react-router-dom';
import HomeTopProducts from '../components/home/HomeTopProducts/HomeTopProducts.component';

function Testpage() {

    const { asd } = useParams();

    return(
        <div>
            params: {asd}
            <HomeTopProducts />
        </div>
    );
}


export default Testpage;