// CancelConfirmationModal.js
import React from 'react';
import Modal from 'react-modal';

// Style for the modal content
const modalStyles = {
  content: {
    background: '#333533',
    color: 'white',
    margin: 'auto',
    maxWidth: '300px',
    maxHeight: '200px', 
    border: '2px solid #F5CB5C',
    borderRadius: '5px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
};

// Modal component
function CancelConfirmationModal({ isOpen, onRequestClose, onCancelConfirm }){
  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyles}
      contentLabel="Confirm Cancelation"
    >
      <h2>Confirmare anulare</h2>
      <p>Esti sigur ca vrei sa anulezi comanda?</p>
      <button onClick={onCancelConfirm} style={{margin: '2px', border: '2px solid red', borderRadius: '2px', height: '2rem', width: '7rem', background: 'black', color: 'white', cursor: 'pointer'}}>Da, Anuleaza!</button>
      <button onClick={onRequestClose} style={{margin: '2px', border: '2px solid #F5CB5C', borderRadius: '2px', height: '2rem', width: '7rem', background: 'black', color: 'white', cursor: 'pointer'}}>Nu</button>
    </Modal>
  );
};

export default CancelConfirmationModal;
