import React from 'react';
import Header from '../../components/Header/Header.component';
import SideBar from '../../components/Sidebar/SideBar.component';
import ShopBody from '../../layouts/Shop/Shop.layout';
import CartBar from '../../components/Cart/CartBar.component';

function Shop() {

    const [navState, setNavState] = React.useState(false);
    const [cartOpen, setCartOpen] = React.useState(false);

    const toggleCart = () => {
        setCartOpen(!cartOpen);
    }

    const toggleNav = () => {
        setNavState(prevState => !prevState);
    };

    return(
        <>
            <Header 
                toggleNav = {toggleNav}
                toggleCart={toggleCart}
                navIsToggled = {navState}
            />
            <CartBar isOpen = {cartOpen}/>
            <SideBar 
                navIsToggled = {navState}
            />
            <ShopBody
                navIsToggled = {navState}
            />
        </>
    );
}

export default Shop;