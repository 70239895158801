import React from 'react';
import './SideBar.style.scss';
import SideBarCard from '../crads/SideBarCard/SideBarCard.component';
import logo from '../../assets/images/logos/kalian-logo-horizontal-inverted.jpg';
import hookahs from '../../assets/images/categories/hookahs.jpg';
import flavors from '../../assets/images/categories/flavors.jpg';
import coals from '../../assets/images/categories/coals.webp';
import accessories from '../../assets/images/categories/hookah-accessories.webp';
import store from '../../assets/images/categories/store.jpg';

type Props = {
  navIsToggled: boolean
}

type cardLink ={
  key: number,
  title : string,
  link : string,
  image: string
}

function SideBar({navIsToggled}: Props) {

    const navBarCardLinks: cardLink[] = [
        {
            key: 0,
            title : "Acasă",
            link : "/",
            image : logo,
        },
        {
            key: 1,
            title : "Magazin",
            link : "/shop",
            image : store
        },
        {
            key: 2,
            title : "Arome",
            link : "/shop/arome",
            image : flavors
        },
        {
            key: 3,
            title : "Narghilea",
            link : "/shop/narghilea",
            image : hookahs
        },
        {
            key: 4,
            title : "Cărbuni",
            link : "/shop/carbuni",
            image : coals
        },
        {
            key: 5,
            title : "Accesorii",
            link : "/shop/accesorii",
            image : accessories
        },
    ]

    const renderNavCards = navBarCardLinks.map((cardData) => {
        return(
            <SideBarCard 
                key = {cardData.key}
                title = {cardData.title}
                link = {cardData.link}
                image = {cardData.image}
            />
        )
    }
);

    return(
        <nav className={`sidebar${navIsToggled ? '-open' : ''}`}>
            <div className='sidebar-cards-container'>
                {renderNavCards}
            </div>   
        </nav>
    );
}


export default SideBar;