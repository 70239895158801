import React from 'react';
import './CategoriesCard.style.scss';
import { Link } from 'react-router-dom';

type CategoriesCardProps = {
  link: string;
  image: string;
  title: string;
};

function CategoriesCard({ link, image, title }: CategoriesCardProps) {
  return (
    <div className='categories-card-container'>
      <Link to={link}>
        <div className='categories-card card'>
          <div className='categories-card-image-container'>
            <img className='categories-image' src={image} alt='' />
          </div>
          <div className='categories-card-title-container'>
            <h2 className='categories-card-title'>{title}</h2>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CategoriesCard;
