import React from'react';
import axios from 'axios';
import './SignUpForm.style.scss';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    active: boolean
}

function SignUpForm({active}: Props){
  
    const [emailState, setEmailState] = React.useState('');
    const [pwdState, setPwdState] = React.useState('');
    const [pwd2State, setPwd2State] = React.useState('');
    const [nameState, setNameState] = React.useState('');
    const [statusState, setStatusState] = React.useState(false);
    const [statusShow, setStatusShow] = React.useState(false);
    const [statusText, setStatusText] = React.useState('');

    function emailChange(event: React.FormEvent){
        //@ts-ignore
        setEmailState(event.target.value)
    }

    function pwdChange(event: React.FormEvent){
        //@ts-ignore
        setPwdState(event.target.value)
    }

    function pwd2Change(event: React.FormEvent){
        //@ts-ignore
        setPwd2State(event.target.value)
    }

    function nameChange(event: React.FormEvent){
        //@ts-ignore
        setNameState(event.target.value)
    }

    const handleRegister = async (event: React.FormEvent<HTMLInputElement>) => {
        event.preventDefault();
        setStatusShow(true);
        if(pwdState !== pwd2State){
            return(setStatusText('Cele două parole nu sunt identice!'));
        }
        await axios.post(`${REACT_APP_BACKEND_URL}/api/users/register`,
            {
                "email": emailState,
                "password": pwdState,
                "name": nameState
            }
            )
            .then((res) => {
                if(res.status === 201) {
                    setStatusState(true);
                }
            })
            .catch((error) => {
                setStatusState(false);
                if(error.response.status === 400){
                    setStatusText('Acest email a fost folosit deja!');
                } else {
                    setStatusText('A apărut o problemă! Încercați mai târziu!');
                }
            })
    }

    return (
    <>
        <div className='break'></div>
        <div className = {`sign-in-form-container ${active ? 'inactive' : 'active'}`} >
            <div className={`status ${statusShow? 'show' : ''} ${statusState? 'green' : 'red'}`}>
                {statusState ? 'Înregistrare reușită!' : statusText}
            </div>
            <form>
                <div className="input-container">
                    <div className='label'>Nume</div>
                    <input type="text" className="text-input" placeholder='Nume' name="name" onChange={nameChange} required />
                    <span className='bottom-border'></span>
                </div>
                <div className='break'></div>
                <div className="input-container">
                    <div className='label'>Adresa Email</div>
                    <input type="text" className="text-input" placeholder='Email' name="email" onChange={emailChange} required />
                    <span className='bottom-border'></span>
                </div>
                <div className='break'></div>
                <div className="input-container">
                    <div className='label'>Parola</div>
                    <input type="password" className="text-input" placeholder='Parola' name="password" onChange={pwdChange} required />
                    <span className='bottom-border'></span> 
                </div>
                <div className='break'></div>
                <div className="input-container">
                    <div className='label'>Parola din nou</div>
                    <input type="password" className="text-input" placeholder='Parola din nou' name="password2" onChange={pwd2Change} required />
                    <span className='bottom-border'></span> 
                </div>
                <div className='break'></div>
                <div className="button-container">
                    <input type="submit" onClick={handleRegister} value="Inregistrare"/>
                </div>
            </form>
        </div>
    </>
  )
};

export default SignUpForm;
