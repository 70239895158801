import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logos/kalian-logo-horizontal.jpeg';
import './Header.style.scss';
import NavButton from '../buttons/NavButton/NavButton.component';
import { ReactComponent as ProfileSVG } from '../../assets/svgs/profile.svg';
import { ReactComponent as CartSVG } from '../../assets/svgs/shopping-cart.svg';
import axios from 'axios';
import { useCart } from '../../Contexts/Cart.context';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    toggleNav: () => void;
    toggleCart: () => void;
    navIsToggled: boolean;
};

function Header({ toggleNav, toggleCart, navIsToggled }: Props) {

    const [link, setLink] = React.useState('/profile');
    const { cartQuantity } = useCart();

    React.useEffect(() => {
        if(!window.localStorage.getItem('Authorization')){
            return;
        }
        axios.get(`${REACT_APP_BACKEND_URL}/api/users/admin`, {
                    params:{
                        Authorization: window.localStorage.getItem('Authorization')!
                    }
        }).then((response) => {
            if(response.data.admin){
                setLink('/manage');
            }
        })
    }, [])



    return (
        <header>
            <div className='nav-button-container'>
                <NavButton toggleNav={toggleNav} navIsToggled={navIsToggled} />
            </div>
            <div className='logo-container'>
                <Link to='/'>
                    <img className='logo-image' src={logo} alt='Kalian logo' />
                </Link>
            </div>
            <div className='cart-and-profile-container'>
                <div className='cart-container' onClick={toggleCart}>
                    {cartQuantity > 0 && <div className='items'>{cartQuantity}</div>}
                    <CartSVG />
                </div>
                <div className='profile-container'>
                    <Link to={link}>
                        <ProfileSVG />
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default Header;
