import React from 'react';
import './orderAdmin.style.scss';
import axios from 'axios';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    address: {
        name: String,
        country: String,
        county: String,
        city: String,
        address: String
    }, 
    date: Date, 
    payment: String, 
    product_ids: String[], 
    quantities: number[], 
    shipping: String,
    status: string, 
    userID: String, 
    value: Number,
    _id: String
    products: any[]
}

function OrderAdmin({address, date, payment, product_ids, quantities, shipping, status, userID, value, _id , products}: Props) {
    
    const [order, setOrder] = React.useState({address, date, payment, product_ids, quantities, shipping, status, userID, value, _id});
    const [updated, setUpdated] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setUpdated(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [updated]);

    const handleChange = (event: React.FormEvent) => {
        setOrder((prevData) => {
            return {
                ...prevData,
                //@ts-ignore
                [event.target.name]: event.target.value 
            }
        })
    }

    const saveChanges = (event: React.MouseEvent) => {
        event.preventDefault();

        axios.post(`${REACT_APP_BACKEND_URL}/api/orders/update`, 
            {
                ...order
            },
            {
                params: {
                    Authorization: window.localStorage.getItem('Authorization')!
                }
            }
        )
    }

    let index = 0;

    const renderProducts = product_ids.map((id) => {
        const product = products.find((product) => product._id === id);
        const price: number = product.price;
        const q: number = quantities[index++];
        return <div className='prod' key={product._id}>
            <div><strong>Nume produs: </strong>{product.title}</div>
            <div><strong>Pret/buc.: </strong>{product.price}</div>
            <div><strong>Nr.buc.: </strong>{q}</div>
            <div><strong>Pret total: </strong>{( price * q )}</div>
        </div>
    })

    return(
        <div className='order-card'>
            <h2>Comanda cu id: {_id}</h2>
            <hr></hr>
            <h4>Date livrare</h4>
            <div className='shipping-address'>
                <div><strong>Metoda de livrare:</strong> {shipping}</div>
                <div><strong>Judetul:</strong> {address.county}</div>
                <div><strong>Localitatea:</strong> {address.city}</div>
                <div><strong>Adresa:</strong> {address.address}</div>
                <div><strong>Metoda de plata:</strong> {payment}</div>
                <div><strong>Pret:</strong> {value.toString()}</div>
            </div>
            <hr></hr>
            <h4>Produse</h4>
            <div className='products-quantities'>
                <div>{renderProducts}</div>
            </div>
            <hr></hr>
            <h4>Status comanda</h4>
            <div className='status'>
                <select name = 'status' value={order.status} onChange={handleChange} className='admin-input'>
                    <option value='Pending'>Comanda in asteptare</option>
                    <option value='Recieved'>Comanda preluata</option>
                    <option value='Processing'>Comanda in procesare</option>
                    <option value='Completed'>Comanda finalizata</option>
                    <option value='Shipped'>Comanda livrata</option>
                    <option value='Cancelled'>Comanda anulata</option>
                    <option value='Returned'>Comanda returnata</option>
                </select>
            </div>
            <hr></hr>
            <div className='userID'>
                <strong>Utilizator: </strong>{userID}
            </div>
            <hr></hr>
            <button onClick={saveChanges}>Salveaza!</button>
        </div>
    );
}

export default OrderAdmin;