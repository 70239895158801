import React from 'react';
import './HomeSwiper.style.scss';
import SwiperLib from '../../../lib/Swiper/Swiper.lib';
import image1 from '../../../assets/images/temp/swiper_aroma.jpg';
import image2 from '../../../assets/images/temp/swiper_accesorii.jpg';

type Card = {
  key: number;
  title: string;
  description: string;
  newPrice: number;
  oldPrice: number;
  discount?: number;
  image: string;
  link: string;
};

function HomeSwiper(props: any) {
  const cards: Card[] = [
    {
      key: 0,
      title: '',
      description: 'Cele mai gustoase arome',
      image: image1,
      link: '/shop/arome',
    },
    {
      key: 3,
      title: '',
      description: 'Accesorii de calitate superioră', 
      image: image2,
      link: '/shop/accesorii',
    }
  ];

  return (
    <div className="swiper-container">
      <SwiperLib cards={cards} />
    </div>
  );
}

export default HomeSwiper;
