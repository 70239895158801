import React from 'react';
import { Link } from 'react-router-dom';
import './GenericNotFound.style.scss';
import logo from '../../assets/images/logos/kalian-logo-horizontal.jpeg';

function GenericNotFound() {
    return(
        <div className='not-found'>
            <Link className='home-link' to='/'>
                <img className='logo-image' src={logo} alt='Kalian logo' />
            </Link>
            <p>
                Looks like you are lost... This is you way <Link className='home-link' to='/'>home!</Link>
            </p>
        </div>
    );
}

export default GenericNotFound;