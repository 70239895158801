import React from 'react';
import Header from '../../components/Header/Header.component';
import SideBar from '../../components/Sidebar/SideBar.component';
import ProfileSignIn from '../../layouts/Profile/SignIn/ProfileSignIn.layout';
import ManageAccount from '../../layouts/Profile/ManageAccount/ManageAccount.layout';
import CartBar from '../../components/Cart/CartBar.component';

type Props = {
    isSignedIn: boolean
}

function Profile({isSignedIn}: Props) {

    const [navState, setNavState] = React.useState(false);
    const [cartOpen, setCartOpen] = React.useState(false);

    const toggleCart = () => {
        setCartOpen(!cartOpen);
    }

    const toggleNav = () => {
        setNavState(prevState => !prevState);
    };

    return(
        <>
            <Header 
                toggleNav = {toggleNav}
                toggleCart={toggleCart}
                navIsToggled = {navState}
            />
            <CartBar isOpen = {cartOpen}/>
            <SideBar 
                navIsToggled = {navState}
            />
            { 
                !window.localStorage.getItem('Authorization') && <ProfileSignIn 
                    navIsToggled = {navState}
                />
            }
            { 
                window.localStorage.getItem('Authorization') && <ManageAccount 
                    navIsToggled = {navState}
                />
            }
        </>
    );
}


export default Profile;