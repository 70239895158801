import React from 'react';
import './Shop.style.scss';
import axios from 'axios';
import ProductCard from '../../components/crads/ProductCard/ProductCard.component';
import Divider from '../../components/Divider/Divider.component';
import { useParams } from "react-router-dom";
const  { REACT_APP_BACKEND_URL } = process.env;


type Props = {
    navIsToggled: boolean
}

function ShopBody({ navIsToggled}: Props) {

    const { category } = useParams();

    const [products, setProducts] = React.useState<any>([]);
    const [selectedCategory, setSelectedCategory] = React.useState(category ? category?.toString() : 'All');
    const [selectedSort, setSelectedSort] = React.useState('none');
    
    React.useEffect(()=>{setSelectedCategory(category ? category?.toString() : 'All')},[category])

    const handleCategoryChange = (event: any) => {
        setSelectedCategory(event.target.value);
    };
    
    const handleSortChange = (event: any) => {
        setSelectedSort(event.target.value);
    };

    React.useEffect( () => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/products/`)
            .then((res) => {
                //@ts-ignore
                setProducts(products => products=res.data);
            })
    }, [])


    const filteredProducts = selectedCategory === 'All'
    ? products
    : products.filter((product: any) => product.category === selectedCategory);

    let sortedProducts = [...filteredProducts];

    if (selectedSort === 'NameAsc') {
        sortedProducts.sort((a, b) => a.title.localeCompare(b.name));
    } else if (selectedSort === 'NameDesc') {
        sortedProducts.sort((a, b) => b.title.localeCompare(a.name));
    } else if (selectedSort === 'PriceAsc') {
        sortedProducts.sort((a, b) => a.price - b.price);
    } else if (selectedSort === 'PriceDesc') {
        sortedProducts.sort((a, b) => b.price - a.price);
    }

    //@ts-ignore
    const renderproductCards = sortedProducts.map((cardData) => {
        return(
            <ProductCard 
                key = {cardData._id}
                id = {cardData._id}
                title = {cardData.title}
                topBanner = {cardData.topBanner}
                bottomBanner = {cardData.bottomBanner}
                image = {`${REACT_APP_BACKEND_URL}/public/images/${cardData.path}/${cardData.images[0]}`}
                quantity={cardData.quantity}
                rating = {cardData.rating}
                link = {`/product/${cardData._id}`}
                price = {cardData.price}
                category = {cardData.category}
            />
        )
    });

    return(
        <main className={`main-body-container${navIsToggled ? '-to-right' : ''}`}>
            <div className='main-body'>
                <Divider text = 'Filtre' />
                    <div className='filters'>
                        <div className='category'>
                            <label htmlFor="category-filter">Categorii: </label>
                            <select id="category-filter" value={selectedCategory} onChange={handleCategoryChange}>
                            <option value="All">Toate</option>
                            <option value="arome">Arome</option>
                            <option value="narghilea">Narghilea</option>
                            <option value="carbuni">Cărbuni</option>
                            <option value="accesorii">Accesorii</option>
                            </select>
                        </div>
                        <div className='sort'>
                            <label htmlFor="sort-by">Ordoneaza dupa: </label>
                            <select id="sort-by" value={selectedSort} onChange={handleSortChange}>
                            <option value="None">Implicit</option>
                            <option value="NameAsc">Nume (A-Z)</option>
                            <option value="NameDesc">Nume (Z-A)</option>
                            <option value="PriceAsc">Preț crescător</option>
                            <option value="PriceDesc">Preț descrescător</option>
                            </select>
                        </div>
                    </div>
                <Divider text = 'Produse' />
                <div className='products-container'>
                    {renderproductCards}
                </div>
            </div>
        </main>
    );
}

export default ShopBody;