import React from 'react';
import Header from '../../components/Header/Header.component';
import SideBar from '../../components/Sidebar/SideBar.component';
import ProductLayout from '../../layouts/Product/Product.layout';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CartBar from '../../components/Cart/CartBar.component';
const  { REACT_APP_BACKEND_URL } = process.env;

function ProductPage() {

    const { id } = useParams();

    const [navState, setNavState] = React.useState(false);
    const [product, setProduct] = React.useState({});
    const [error, setError] = React.useState(false);
    const [loadnig, setLoading] = React.useState(true);
    const [cartOpen, setCartOpen] = React.useState(false);

    const toggleCart = () => {
        setCartOpen(!cartOpen);
    }

    React.useEffect( () => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/products/${id}`)
            .then((response) => {
                setProduct(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(true);
            })
    },[id])

    const toggleNav = () => {
        setNavState(prevState => !prevState);
    };

    return(
        <>
            <Header 
                toggleNav = {toggleNav}
                toggleCart={toggleCart}
                navIsToggled = {navState}
            />
            <CartBar isOpen = {cartOpen}/>
            <SideBar 
                navIsToggled = {navState}
            />
            <ProductLayout
                //@ts-ignore
                product = {product}
                error = {error} 
                navIsToggled = {navState} 
                loading = {loadnig}
            />
        </>
    );
}

export default ProductPage;