import React from 'react';
import './productAdmin.style.scss';
import DeleteButton from '../../buttons/DeleteButton/DeleteButton.component';
import axios from 'axios';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    _id: String,
    title: String,
    category: String,
    description: String,
    price: Number,
    quantity: Number,
    images: Array<String>,
    path: String,
    showDelete?: Boolean
}

function ProductAdmin({_id, title, category, description, price, quantity, images, path, showDelete = false }: Props) {

    const[product, setProduct] = React.useState({_id, title, category, description, price, quantity, images, path});
    const[updated, setUpdated] = React.useState(false);
    const [fileList, setFileList] = React.useState<FileList | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
    };

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setUpdated(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [updated]);

    const saveChanges = (event: React.MouseEvent) =>{
        event.preventDefault();

        const data = new FormData();
        files.forEach((file, i) => {
            data.append(`file-${i}`, file, file.name);
        });
        //@ts-ignore
        data.append('title', product.title);
        //@ts-ignore
        data.append('description', product.description);
        //@ts-ignore
        data.append('category', product.category);
        //@ts-ignore
        data.append('price', product.price);
        //@ts-ignore
        data.append('images[]', []);
        product.images.forEach((image) => {
            //@ts-ignore
            data.append('images[]', image);
        })
        //@ts-ignore
        data.append('_id', product._id);
        //@ts-ignore
        data.append('quantity', product.quantity);
        //@ts-ignore
        data.append('path', product.path);

        if(product._id !== 'new'){
            axios.post(`${REACT_APP_BACKEND_URL}/api/products/update`, data, {
                params: {
                    "Authorization": window.localStorage.getItem('Authorization')!
                }
            }
            ).then((response: any) => {
                if(response.status === 200){
                    setUpdated(true);
                }
            }).catch();
        } else if(product._id === 'new') {
            data.delete('_id');
            axios.post(`${REACT_APP_BACKEND_URL}/api/products/add`, data, {
                params: {
                    "Authorization": window.localStorage.getItem('Authorization')!
                }
            }
            ).then((response: any) => {
                if(response.status === 200){
                    setUpdated(true);
                }
            }).catch();
        }
    }

    const handleChange = (event: React.FormEvent) => {
        setProduct((prevData) => {
            return {
                ...prevData,
                //@ts-ignore
                [event.target.name]: event.target.value 
            }
        })
    }

    const deleteProduct = (event: React.MouseEvent) => {
        axios.delete(`${REACT_APP_BACKEND_URL}/api/products/delete`,{
            data : {
                _id: product._id,
            },
            params: {
                "Authorization": window.localStorage.getItem('Authorization')!
            }
        })
    }

    const deleteImage = (image: String) => {

        const imagesArray = product.images.filter((element) => element !== image)

        setProduct((prevData) => {
            return {
                ...prevData,
                images: imagesArray
            }
        })
    }

    let i = 0;

    const renderImages = product.images.map((image) => {
        return (
            <div className='image-container' key={i++}>
                <div className='delete-button-container'>
                    <DeleteButton handleDelete={() => deleteImage(image)} /> 
                </div>
                <img className='admin-image' alt='product' src={`${REACT_APP_BACKEND_URL}/public/images/${path}/${image}`}/>
            </div>
        )
    })

    const files = fileList ? [...fileList] : [];

    return(
        <div className='product-container'>
            { updated && <h3 className='updated'>Schimbari salvate!</h3> }
            <h3 className='title'>{product.title}</h3>
            <hr></hr>
            <form>
                <p>Titlu</p>
                {/*//@ts-ignore*/}
                <input type='text' name='title' onChange={handleChange} value={product.title} className='admin-input'/>
                <p>Imagini</p>
                <div className='images'>
                    {renderImages}
                    <p>Adauga imagini</p>
                    <input type="file" onChange={handleFileChange} accept='.jpg, .jpeg, .png' multiple className='admin-input'/>
                </div>
                <p>Categorie</p>
                {/*//@ts-ignore*/}
                <select value={product.category} onChange={handleChange} name='category' className='admin-input'>
                    <option value='arome'>arome</option>
                    <option value='carbuni'>carbuni</option>
                    <option value='narghilea'>narghilea</option>
                    <option value='accesorii'>accesorii</option>
                </select>
                <p>Descriere</p>
                {/*//@ts-ignore*/}
                <textarea value={product.description} onChange={handleChange} name='description' className='admin-input'/>
                <p>Pret</p>
                {/*//@ts-ignore*/}
                <input type='number' value={product.price} onChange={handleChange} name='price' className='admin-input'/>
                <p>Cantitate</p>
                {/*//@ts-ignore*/}
                <input type='number' value={product.quantity} onChange={handleChange} name='quantity' className='admin-input'/>

                <button type='submit' onClick={saveChanges}>Salveaza</button>
                {showDelete && <button type='button' onClick={deleteProduct}>Sterge produsul</button>}
            </form>
        </div>
    );
}

export default ProductAdmin;