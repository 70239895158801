import React from 'react';
import axios from 'axios';
import ProductAdmin from '../../components/admin/productAdmin/productAdmin.component';
import './ManageStore.style.scss';
import OrderAdmin from '../../components/admin/ordersAdmin/orderAdmin.component';
const  { REACT_APP_BACKEND_URL } = process.env;

function ManageStore() {
    
    const [tab, setTab] = React.useState(0);
    const [products, setProducts] = React.useState([]);
    //const [users, setUsers] = React.useState();
    const [orders, setOrders] = React.useState([]);

    //products
    React.useEffect(() => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/products/`)
        .then((response) => {
            setProducts(response.data);
        });
    }, [])

    React.useEffect(() => {
        axios.get(`${REACT_APP_BACKEND_URL}/api/orders/`,{params: {Authorization: window.localStorage.getItem('Authorization')! }})
        .then((response) => {
            setOrders(response.data);
        });
    }, [])

    const renderProductAdmin = products.map((product: any) => {
        return <ProductAdmin 
            key = {product._id}
            _id = {product._id}
            title = {product.title}
            category = {product.category}
            description = {product.description}
            price = {product.price}
            quantity = {product.quantity}
            images = {product.images}
            path = {product.path}
            showDelete={true}
        />
    })

    const renderOrderAdmin = orders.map((order: any) => {
        return <OrderAdmin  
            key={order._id} 
            address={order.address}
            date={order.date}
            payment={order.payment}
            product_ids={order.product_ids}
            quantities={order.quantities}
            shipping={order.shipping}
            status={order.status}
            userID={order.userID}
            value={order.value}
            _id={order._id}
            products={products}
        />
    })

    return(
        <div className='control-panel-container'>
            <div className='title-container'>
                <h1>
                    Administrare magazin
                </h1>
            </div>
            <div className='control-panel'>
                <button key = {0} onClick={() => {setTab(0); document.title = "Manage store - Products";}} >Produse</button>
                <button key = {1} onClick={() => {setTab(1); document.title = "Manage store - Orders";}} >Comenzi</button>
                <button key = {2} onClick={() => {setTab(2); document.title = "Manage store - Products";}} >Utilizatori</button>
            </div>
            <div className='options-container'>
                <div className='admin-cards'>
                    {tab === 0 && <>
                        <ProductAdmin 
                            key = {0}
                            _id = {'new'}
                            title = {'Produs nou'}
                            category = {'arome'}
                            description = {'descriere'}
                            price = {0}
                            quantity = {0}
                            images = {[]}
                            path = {'new'}
                        />
                        <hr></hr>
                        {renderProductAdmin}
                    </>}
                    {tab === 1 && <>
                        Orders
                        <hr></hr>
                        {renderOrderAdmin}
                    </>}
                    {tab === 2 && <>
                        Users
                        <hr></hr>
                        Work in progress...
                    </>}
                </div>
            </div>
        </div>
    );
}

export default ManageStore;