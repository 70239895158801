import React from 'react';
import './FirstVisit.style.scss';
import logo from '../../assets/images/logos/kalian-logo-horizontal.jpeg';
import smoke from '../../assets/images/smoke/smoke.png';

type Props ={
    handleClick: () => void
}

function FirstVisit({handleClick}: Props) {
    return(
        <div className='firstVisit-modal-container'>
            <img className='behind-modal' src={smoke} alt='smoke behind modal' />
            <div className='modal'>
                <img src={logo} alt='kalian logo' className='modal-logo' />
                <h1 className = 'title' style={{ display: "none"}}>Kalian - Magazin online</h1>
                <p className='modal-title'>Ai peste 18 ani?</p>
                <div className='modal-buttons'>
                    <a className='modal-button modal-button-green' onClick={handleClick} href={window.location.href}>Da</a>
                    <a className='modal-button modal-button-red' href='https://www.consuma-responsabil.ro/'>Nu</a>
                </div>
            </div>
        </div>
    );
}

export default FirstVisit;