import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import Router from './Router';
import './index.style.scss';
import { CartProvider } from './Contexts/Cart.context'
import MaintenancePage from './pages/maintenance/maintenance.page';
const { REACT_APP_MAINTENANCE } = process.env;


// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

const maintenance = REACT_APP_MAINTENANCE;

if (maintenance === 'true'){
  root.render(
    <StrictMode>
      <MaintenancePage/>
    </StrictMode>
  )
} else {
  root.render(
    <StrictMode>
      <CartProvider>
        <Router />
      </CartProvider>
    </StrictMode>,
  );
}
