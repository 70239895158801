import React from 'react';
import './Divider.style.scss';

type Props = {
    text: string,
}

function Divider({text}: Props) {
    return(
        <div className='divider do-not-cross'>
            <div className='before'></div>
            <h2>{text}</h2>
            <div className='after'></div>
        </div>
    );
}

export default Divider;