import React from 'react';
import './LeftRightButton.style.scss';

type Prop ={
    direction: string,
    onClick: () => void
}

function LeftRightButton({direction, onClick}: Prop) {
    return(
        <button className={`button-${direction}`} onClick={onClick}>
            <span className={`button-line line-top-${direction}`}></span>
            <span className={`button-line line-bottom-${direction}`}></span>
        </button>
    );
}

export default LeftRightButton;