import React from'react';
import './SignInForm.style.scss';
import axios from 'axios';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    active: boolean
}


function SignInForm({active}: Props){

    const [emailState, setEmailState] = React.useState('');
    const [pwdState, setPwdState] = React.useState('');
    const [emailError, setEmailErrorState] = React.useState(false);
    const [pwdError, setPwdErrorState] = React.useState(false);
    const [credentialsError, setCredentialsError] = React.useState(false);

    function emailChange(event: React.FormEvent){
        //@ts-ignore
        setEmailState(event.target.value)
    }

    function pwdChange(event: React.FormEvent){
        //@ts-ignore
        setPwdState(event.target.value)
    }

    function handleSignIn(event: React.FormEvent<HTMLInputElement>){
        event.preventDefault();
        axios.post(`${REACT_APP_BACKEND_URL}/api/users/login`,
            {
                email: emailState,
                password: pwdState
            },
            {
                data: {
                  'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response);
                window.localStorage.setItem("Authorization", response.data.Authorization);
                axios.get(`${REACT_APP_BACKEND_URL}/api/users/admin`, {
                    params:{
                        Authorization: response.data.Authorization
                    }
                }).then((response) =>{
                    if(response.data.admin){
                        window.location.href = '/manage';
                    } else {
                        window.location.reload(); 
                    }
                });
            })
            .catch((error) => {
                if(error.response.data === "\"email\" is not allowed to be empty"){
                    setEmailErrorState(true);
                    setPwdErrorState(false);
                    setCredentialsError(false);
                } else if(error.response.data === '"password" is not allowed to be empty'){
                    setPwdErrorState(true);
                    setEmailErrorState(false);
                    setCredentialsError(false);
                } else if(error.response.data === 'Invalid credentials'){
                    setCredentialsError(true);
                    setEmailErrorState(false);
                    setPwdErrorState(false);
                }
            });
        
    }

  return (
    <>
        <div className='break'></div>
        <div className = {`sign-in-form-container ${active ? 'active' : 'inactive'}`} >
            {emailError && <div className='error-container'><p className='error'>Câmpul email nu poate fi gol!</p></div>}
            {pwdError && <div className='error-container'><p className='error'>Câmpul parolă nu poate fi gol!</p></div>}
            {credentialsError && <div className='error-container'><p className='error'>Email sau parolă greșită!</p></div>}
            <form>
                <div className="input-container">
                    <div className='label'>Adresa Email</div>
                    <input name="email" type="email" className="text-input" placeholder='Email' onChange={emailChange} required />
                    <span className='bottom-border'></span>
                </div>
                <div className='break'></div>
                <div className="input-container">
                    <div className='label'>Parolă</div>
                    <input name="password" type="password" className="text-input" placeholder='Parolă' onChange={pwdChange} required />
                    <span className='bottom-border'></span> 
                </div>
                <div className='break'></div>
                <div className="button-container">
                    <input type="submit" value="Intră în cont" onClick={handleSignIn}/>
                </div>
            </form>
        </div>
    </>
  ) 
};

export default SignInForm;
