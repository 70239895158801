import React from 'react';
import './HomeBody.style.scss';
import HomeSwiper from '../../components/home/HomeSwiper/HomeSwiper.component';
import Divider from '../../components/Divider/Divider.component';
import HomeCategories from '../../components/home/HomeCategories/HomeCategories.component';
import HomeTopProducts from '../../components/home/HomeTopProducts/HomeTopProducts.component';


type Props = {
    navIsToggled: boolean
}

function HomeBody({navIsToggled}: Props) {
    return(
        <main className={`main-body-container${navIsToggled ? '-to-right' : ''}`}>
            <div className='main-body'>
                {/* <Divider 
                    text = 'Pagina este in dezvoltare, vor aparea schimbari frecvente!'
                /> */}
                <div className='hero-section'>
                    <HomeSwiper />
                    {/* <div className='hero-left'>
                        Pagina este in dezvoltare, vor aparea schimbari frecvente, si functii noi!
                    </div>
                    <div className='hero-right'>
                        <HomeSwiper />
                    </div> */}
                </div>
                <Divider 
                    text='Categorii'
                />
                <HomeCategories/>
                <Divider 
                    text='Cele mai populare'
                />
                <HomeTopProducts/>
            </div>
        </main>
    );
}


export default HomeBody;