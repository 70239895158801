import React from 'react';
import axios from 'axios';
import GenericNotFound from '../../pages/genericNotFound/GenericNotFound.page';
const  { REACT_APP_BACKEND_URL } = process.env;

type Props = {
    protectedElement: JSX.Element
}

function ProtectedRoute ({protectedElement}: Props) {

    const [admin, setAdmin] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if(!window.localStorage.getItem('Authorization')){
            setLoading(false);
        }

        axios.get(`${REACT_APP_BACKEND_URL}/api/users/admin`, {
                    params:{
                        Authorization: window.localStorage.getItem('Authorization')!
                    }
        }).then((response) => {
            if(response.data.admin){
                setAdmin(true);
            }
            setLoading(false);
        })
    }, []);

    if(loading){
        return (<div>loading...</div>)
    }

    if(admin && !loading){
        return protectedElement;
    }
    
    if(!admin && !loading){
        return <GenericNotFound />
    }
}

export default ProtectedRoute;